import React, { useState } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,  
  } from "react-router-dom";
import App from '../App'
import Servicios from '../Views/Servicios'
import Sistemas from '../Views/Sistemas'
import Footer from '../Component/Footer'
import AppBarMenu from '../Component/AppBarMenu'
import DrawerRight from '../Component/DrawerRight';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

const Container = () => {
  const classes = useStyles();
  const [abrir, setAbrir] = useState(false);  

  const accionAbrir = () => {
    setAbrir(!abrir)
  }  
  
  return (
    <Router>
      <div id="back-to-top-anchor" />
      <AppBarMenu       
        accionAbrir={accionAbrir}
      >
      </AppBarMenu>      
      <div>                        
        <Switch>
          <Route exact path="/servicios">
            <Servicios />
          </Route>
          <Route exact path="/sistemas">
            <Sistemas />
          </Route>
          <Route exact path="/">
            <App />
          </Route>
        </Switch>
      </div>
      <Footer />
      <DrawerRight
        variant="temporary"
        open={abrir}
        onClose={accionAbrir}
      />
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Router>
    )
}

export default Container
